// Webpack Imports
import * as bootstrap from 'bootstrap';

(function () {
    'use strict';

    // Focus input if Searchform is empty
    [].forEach.call(document.querySelectorAll('.search-form'), (el) => {
        el.addEventListener('submit', function (e) {
            var search = el.querySelector('input');
            if (search.value.length < 1) {
                e.preventDefault();
                search.focus();
            }
        });
    });

    // Initialize Popovers: https://getbootstrap.com/docs/5.0/components/popovers
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl, {
            trigger: 'focus',
        });
    });
})();

jQuery(document).ready(function ($) {
    $('.slider').slick({
        fade: true,
        speed: 400,
        cssEase: 'linear',
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
    });
    lightbox.option({
        resizeDuration: 200,
        wrapAround: true,
        albumLabel: 'Zdjęcie %1 z %2',
    });
    //

    // Załadowanie strony + scroll + otwarcie elementu
    var anchor = window.location.hash;
    if (anchor.includes('?')) {
        var sectionName = anchor.split('?')[0];
        var ofertaValue = anchor.split('?')[1].split('=')[1];
        $('html, body').animate(
            {
                scrollTop: $(sectionName).offset().top - 150,
            },
            200
        );
        $('#accordion-item-' + ofertaValue + ' .accordion-collapse').collapse('show');
    }

    // zmiana linku po kliknięciu w ofertę
    $('.accordion-item').click(function () {
        var anchor = '#cennik?oferta=' + $(this).data('id-anchor');
        var url = window.location.href;
        // Sprawdź, czy URL zawiera już jakiś anchor
        if (url.indexOf('#') === -1) {
            url = url.split('?')[0] + anchor;
        } else {
            url = url.split('#')[0] + anchor;
        }
        window.location.replace(url);
    });

    // linki w stopce
    $('#menu-menu-stopka-2 a').click(function () {
        var urlParams = new URLSearchParams($(this).attr('href'));
        var firstParam = urlParams.get(urlParams.keys().next().value);
        $('#accordion-item-' + firstParam + ' .accordion-collapse').collapse('show');
        $('html, body').animate(
            {
                scrollTop: $('#cennik').offset().top - 150,
            },
            200
        );
    });
});
